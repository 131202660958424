import EncryptUtility from "../../utility/js/encrypt-utility";
import commonCarrier from "../../common/js/common-api-service";
import carrierMasterCarrier from "./service-level-service";
import Utility from "../../../shared/utility.js";
import commonApiService from "../../common/js/common-api-service";
export default {
  name: "carrierComp",
  props: {
    name: String,
    carrierCompCalled: Boolean,
    actionList: Array,
    singleCarrierDet: Array,
    carrierKey: String,
  },
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    friendlyName: "",
    selectedProject: "",
    projectList: [],
    selectedCarrier: "",
    carrierList: [],
    selectedBusiness: "",
    businessList: [],
    stateDataShipBill: [],
    accNumber: "",
    sameAsAbove: false,
    showBillCountryDrop: false,
    clientId: "",
    clientSecret: "",
    projSelected: true,
    edit: true,
    max100Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 100 || "Max Length must be 100 characters or less"],
    max32Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 32 || "Max Length must be 32 characters or less"],
    max35Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 35 || "Max Length must be 35 characters or less"],
    required: [(v) => !!v || "Field is required"],
    stateRule: [(v) => !!v || "Field is required", (v) => /^[a-zA-Z0-9 ]+$/.test(v) || "special character are not allowed"],
    emailRule: [(v) => /.+@.+\..+/.test(v) || "Enter valid Email"],
    zipcode: [(v) => /^\d{5}(-\d{4})?$/.test(v) || "Enter valid Postal"],
    postalCodeNonUS: [(v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Invalid Postal Code"],
    carrierDelDialog: false,
    isActive: false,
    deleteAllowed: false,
    carrierStatusNotDialog: false,
    carrierEditable: false,
    carrierStatusChangeDialog: false,
    showShipCountryDrop: false,
    countryData: [],
    stateDataShip: [],
    carrierName: "",
    requiredField: [
      (value) => {
        if (value !== null && value !== "") return true;
        else return "Field is requred.";
      },
    ],
    shipObj: {
      sh_title: "",
      sh_first: "",
      sh_last: "",
      sh_company: "",
      sh_address1: "",
      sh_address2: "",
      sh_city: "",
      sh_state: "",
      sh_zip: "",
      sh_country: "USA",
      sh_phone1: "",
      sh_phone2: "",
      sh_cell: "",
      sh_fax: "",
      sh_email: "",
    },

    billObj: {
      title: "",
      first: "",
      last: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "USA",
      phone1: "",
      phone2: "",
      cell: "",
      fax: "",
      email: "",
    },
  }),
  async created() {
    this.getProjectData();
    this.getCarrierData();
    if (this.carrierKey !== "" && this.carrierKey !== null && this.carrierKey !== undefined) {
      this.selectedCarrier = parseInt(this.carrierKey);
      this.carrierEditable = true;
    }
    if (this.name !== "newCarrier") {
      this.carrierLvlDet();
      this.isActive = this.singleCarrierDet.IsActive;
      this.carrierEditable = true;
    } else this.getCountryData();
  },
  mounted() {},
  methods: {
    //Carrier Change event
    carrierChange() {
      this.carrierName = this.carrierList.filter((obj) => obj.carrierid == this.selectedCarrier);
    },
    //to check if its a number of not
    isNumber(event, loc) {
      if (loc == "number") {
        let value = Utility.isNumber(event);
        return value;
      } else {
        let value = Utility.isNumberWithHypen(event);
        return value;
      }
    },
    //Reset Function
    resetFunction() {
      this.friendlyName = "";
      this.selectedProject = "";
      this.selectedCarrier = "";
      this.selectedBusiness = "";
      this.clearBillObj();
      this.clearShipObj();
      this.accNumber = "";
      this.carrierName = "";
      this.clientId = "";
      this.clientSecret = "";
      this.projSelected = true;
      this.edit = true;
      this.carrierDelDialog = false;
      this.carrierStatusNotDialog = false;
      this.deleteAllowed = false;
      this.carrierStatusChangeDialog = false;
      if (this.carrierKey !== "" && this.carrierKey !== null && this.carrierKey !== undefined) {
        this.selectedCarrier = parseInt(this.carrierKey);
      }
      if (this.name != "newCarrier") {
        this.carrierLvlDet();
        this.isActive = this.singleCarrierDet.IsActive;
        this.carrierEditable = true;
      }
      this.$refs.carrierForm.resetValidation();
    },
    //Get Project Data
    async getProjectData() {
      this.projectList = [];
      let data = await commonCarrier.getProjectAllComp("get", this.userId);
      if (data.message !== "NA") {
        this.projectList = data.ProjectInfo;
      }
    },
    //Get Business Data
    async getBusinessData() {
      if (this.selectedProject !== null && this.selectedProject !== undefined) {
        let obj = {
          UserId: this.userId,
          ProjKey: parseInt(this.selectedProject),
          BuKey: 0,
          IncludeEmptyBU: 1,
          WithinSameLocation: 0,
          loca_key: 0,
        };
        let businessData = await commonCarrier.postBusinessData("post", obj);
        if (businessData.message != "NA") {
          this.businessList = businessData.map((x) => ({
            bu_id: x.Bu_id,
            business: x.Business,
          }));
          if (this.selectedProject == 0) {
            this.selectedBusiness = 0;
            this.projSelected = true;
          } else if (this.name == "newCarrier" && this.selectedProject !== 0) {
            this.projSelected = false;
          } else {
            this.selectedBusiness = 0;
            this.projSelected = false;
          }
        }
      } else {
        this.selectedBusiness = "";
      }
    },
    //Get Carrier List Data
    async getCarrierData() {
      this.carrierList = [];
      let data = await carrierMasterCarrier.getCarriersList("get", this.userId);
      if (data.message !== "NA") {
        this.carrierList = data;
      }
    },
    //Add Carrier Level
    async addCarrier() {
      let shippingAddress = [];
      let billAddress = [];
      shippingAddress.push(this.shipObj);
      billAddress.push(this.billObj);
      if (this.$refs.carrierForm.validate() && this.$refs.shipCarrierForm.validate() && this.$refs.billCarrierForm.validate()) {
        let addSubmit = {
          UserId: this.userId,
          pass: 2,
          account_id: 0,
          acct_name: this.friendlyName,
          carrier_key: this.selectedCarrier == null || this.selectedCarrier == "" ? 0 : parseInt(this.selectedCarrier),
          proj_key: parseInt(this.selectedProject),
          bu_key: parseInt(this.selectedBusiness),
          accountno: this.accNumber,
          client_id: this.clientId,
          client_secret: this.clientSecret,
          status: true,
          ShipperAddress: JSON.stringify(shippingAddress),
          BillToAddress: JSON.stringify(billAddress),
        };
        let newCarrierLvl = await carrierMasterCarrier.postCarrierMaster("post", addSubmit);
        if (newCarrierLvl.message != "NA") {
          this.$emit("carrierAdded", newCarrierLvl.Result[0].Account_Id);
          let Alert = {
            type: "success",
            isShow: true,
            message: newCarrierLvl.Result[0].Message,
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else if (!this.$refs.carrierForm.validate()) {
        Utility.validateForm("carrierForm", this);
      } else if (!this.$refs.shipCarrierForm.validate()) {
        Utility.validateForm("shipCarrierForm", this);
      } else if (!this.$refs.billCarrierForm.validate()) {
        Utility.validateForm("billCarrierForm", this);
      }
    },
    //Update Carrier
    async updateCarrier() {
      let shippingAddress = [];
      let billAddress = [];
      shippingAddress.push(this.shipObj);
      billAddress.push(this.billObj);
      if (this.$refs.carrierForm?.validate() && this.$refs.shipCarrierForm?.validate() && this.$refs.billCarrierForm?.validate()) {
        let updateSubmit = {
          UserId: this.userId,
          pass: 3,
          account_id: parseInt(this.singleCarrierDet.account_id),
          acct_name: this.friendlyName,
          carrier_key: this.selectedCarrier == null || this.selectedCarrier == "" ? 0 : parseInt(this.selectedCarrier),
          proj_key: parseInt(this.selectedProject),
          bu_key: parseInt(this.selectedBusiness),
          accountno: this.accNumber,
          client_id: this.clientId,
          client_secret: this.clientSecret,
          status: this.isActive,
          ShipperAddress: JSON.stringify(shippingAddress),
          BillToAddress: JSON.stringify(billAddress),
        };
        let updateCarrierLvl = await carrierMasterCarrier.postCarrierMaster("post", updateSubmit);
        if (updateCarrierLvl.message != "NA") {
          this.$emit("carrierUpdated", updateCarrierLvl.Result[0].Account_Id);
          let Alert = {
            type: "success",
            isShow: true,
            message: updateCarrierLvl.Result[0].Message,
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else if (!this.$refs.carrierForm.validate()) {
        Utility.validateForm("carrierForm", this);
      } else if (!this.$refs.shipCarrierForm.validate()) {
        Utility.validateForm("shipCarrierForm", this);
      } else if (!this.$refs.billCarrierForm.validate()) {
        Utility.validateForm("billCarrierForm", this);
      }
    },
    //Get details of the Carrier Level
    async carrierLvlDet() {
      let obj = {
        UserId: this.userId,
        pass: 4,
        account_id: parseInt(this.singleCarrierDet.account_id),
        acct_name: "",
        carrier_key: 0,
        proj_key: 0,
        bu_key: 0,
        accountno: "",
        client_id: "",
        client_secret: "",
        status: true,
      };
      let data = await carrierMasterCarrier.postCarrierMaster("post", obj);

      if (data.message != "NA") {
        this.getCountryData();
        if (Utility.isJson(JSON.stringify(data[0]?.ShipperAddress[0]))) {
          this.shipObj = JSON.parse(JSON.stringify(data[0]?.ShipperAddress[0]));
        }
        if (Utility.isJson(JSON.stringify(data[0]?.BillToAddress[0]))) {
          this.billObj = JSON.parse(JSON.stringify(data[0]?.BillToAddress[0]));
        }
        this.friendlyName = data[0]?.FriendlyName;
        this.selectedCarrier = data[0]?.carrier_key;
        this.selectedProject = data[0]?.proj_key;
        this.carrierChange();
        if (this.selectedProject !== null && this.selectedProject !== undefined) {
          this.getBusinessData();
          this.selectedBusiness = data[0]?.bu_key;
        }
        this.accNumber = data[0]?.accountno;
        this.clientId = data[0]?.clientid;
        this.clientSecret = data[0]?.clientsecret;
        this.deleteAllowed = data[0]?.isAllowedDelete;
        this.$refs.carrierForm.resetValidation();
      }
    },
    //Delete carrier level
    deleteCarrierLvl() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        UserId: this.userId,
        pass: 6,
        account_id: parseInt(this.singleCarrierDet.account_id),
        acct_name: "",
        carrier_key: 0,
        proj_key: 0,
        bu_key: 0,
        accountno: "",
        client_id: "",
        client_secret: "",
        status: true,
      };
      this.axios
        .post("/cp/carriers_api_master", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response?.data?.body?.message);
            let successMsg = responseData.Result[0].Message;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: successMsg,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.carrierDelDialog = false;
            this.closeDialog();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    //Carrier Delete
    carrierDel() {
      if (!this.deleteAllowed) {
        this.carrierStatusNotDialog = true;
      } else {
        this.carrierDelDialog = true;
      }
    },
    //Status Change
    statusChange() {
      if (!this.isActive) {
        if (!this.deleteAllowed) {
          this.carrierStatusNotDialog = true;
        } else {
          this.carrierStatusChangeDialog = true;
        }
      } else {
        this.carrierStatusChangeDialog = true;
      }
    },
    //Close button click event
    closeDialog() {
      this.clearBillObj();
      this.clearShipObj();
      this.carrierCompCalled = false;

      this.$emit("hide");
    },
    //Edit Carrier
    editCarrier() {
      this.edit = false;
      if (this.selectedProject == 0) {
        this.selectedBusiness = 0;
        this.projSelected = true;
      } else if (this.selectedProject !== 0) {
        this.projSelected = false;
        this.selectedBusiness = 0;
      }
    },
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList != null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter != undefined && filter != null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Country selected in shipping
    async countrySelected(loc) {
      if (loc == "ship") {
        if (this.shipObj.sh_country && this.shipObj.sh_country !== "") {
          this.shipObj.sh_state = "";
          this.shipObj.sh_zip = "";
          this.$refs.shipCarrierForm.resetValidation();
          let countryStateData = await commonApiService.countryListNew(this.userId, this.shipObj.sh_country, 2, "get");
          this.stateDataShip = countryStateData.StateInfo;
          if (this.stateDataShip.length !== 0) {
            this.showShipCountryDrop = true;
          } else {
            this.showShipCountryDrop = false;
          }
        } else if (this.carrierCompCalled && this.shipObj.sh_country !== "") {
          this.shipObj.sh_state = "";
          this.shipObj.sh_zip = "";
          this.$refs.shipCarrierForm.resetValidation();
          let countryStateData = await commonApiService.countryListNew(this.userId, this.shipObj.sh_country, 2, "get");
          this.stateDataShip = countryStateData?.StateInfo;
          if (this.stateDataShip.length !== 0) {
            this.showShipCountryDrop = true;
          } else {
            this.showShipCountryDrop = false;
          }
        }
      } else {
        if (this.billObj.country && this.billObj.country !== "") {
          this.billObj.state = "";
          this.billObj.zip = "";
          this.$refs.billCarrierForm.resetValidation();
          let countryStateData = await commonApiService.countryListNew(this.userId, this.billObj.country, 2, "get");
          this.stateDataShipBill = countryStateData.StateInfo;
          if (this.stateDataShipBill.length !== 0) {
            this.showBillCountryDrop = true;
          } else {
            this.showBillCountryDrop = false;
          }
        } else if (this.carrierCompCalled && this.billObj.country !== "") {
          this.billObj.state = "";
          this.billObj.zip = "";
          this.$refs.billCarrierForm.resetValidation();
          let countryStateData = await commonApiService.countryListNew(this.userId, this.billObj.country, 2, "get");
          this.stateDataShipBill = countryStateData?.StateInfo;
          if (this.stateDataShip.length !== 0) {
            this.showBillCountryDrop = true;
          } else {
            this.showBillCountryDrop = false;
          }
        }
      }
    },
    onChangeSameAsAbove() {
      this.sameAsAbove
        ? (this.billObj = Object.fromEntries(Object.entries(this.shipObj).map(([key, value]) => [key.replace(/^sh_/, ""), value])))
        : this.clearBillObj();
    },

    clearBillObj() {
      this.$refs.billCarrierForm?.resetValidation();
      this.billObj = {
        title: "",
        first: "",
        last: "",
        company: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phone1: "",
        phone2: "",
        cell: "",
        fax: "",
        email: "",
      };
    },
    clearShipObj() {
      this.$refs.shipCarrierForm?.resetValidation();
      this.shipObj = {
        sh_title: "",
        sh_first: "",
        sh_last: "",
        sh_company: "",
        sh_address1: "",
        sh_address2: "",
        sh_city: "",
        sh_state: "",
        sh_zip: "",
        sh_country: "USA",
        sh_phone1: "",
        sh_phone2: "",
        sh_cell: "",
        sh_fax: "",
        sh_email: "",
      };
    },
    async getCountryData() {
      let countryStateData = await commonApiService.countryListNew(this.userId, "", 1, "get");
      this.countryData = countryStateData.CountryInfo;
      let data2 = await commonApiService.countryListNew(this.userId, this.shipObj.sh_country, 2, "get");
      this.stateDataShip = data2.StateInfo;
      if (this.stateDataShip.length !== 0) {
        this.showShipCountryDrop = true;
      } else {
        this.showShipCountryDrop = false;
      }
      let data3 = await commonApiService.countryListNew(this.userId, this.billObj.country, 2, "get");
      this.stateDataShipBill = data3.StateInfo;
      if (this.stateDataShipBill.length !== 0) {
        this.showBillCountryDrop = true;
      } else {
        this.showBillCountryDrop = false;
      }
    },
    //To check if its a number of not
    postalCodeFormatShip(event, num) {
      this.shipObj.sh_zip = Utility.formatPostalCode(event, num);
    },
    //To check if its a number of not
    postalCodeFormatBill(event, num) {
      this.billObj.zip = Utility.formatPostalCode(event, num);
    },
  },
};
